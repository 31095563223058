.App {
  font-Family: "'Open Sans', sans-serif";
  text-align: center;
  background-color: #08171D --force;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.modal-content {
  background-color: white;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  max-width: 90%;
  width: 500px; 
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
}

.modal-content {
  background-color: rgba(255, 255, 255, 1); 
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  max-width: 80%; 
  width: 500px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5); 
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-inner-content {
  max-height: 70vh; 
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-image img {
  max-height: 150px; 
}

.modal-text {
  margin-top: 20px;
  text-align: center;
}

.modal-buttons {
  display: flex;
  justify-content: space-between; 
  margin-top: 20px;
}

@media (max-width: 600px) {
  .modal-content {
      max-width: 90%;
  }
}

.Intro {
  background-color: #08171D;
}

.Juegos {
  background-color: #08171D;
}
.destacados {
  background-color: #08171D !important;
}

.Ratings {
  background-color: #08171D;
  color: white;
}

.aparecer {
  opacity: 0; 
  animation: fadein ease 3s; 
  animation-fill-mode: forwards; 
}

@keyframes fadein {
  0% {
      opacity: 0; 
  }
  100% {
      opacity: 1; 
  }
}


.Navbar {
  background-color: #0A1A1B !important; 
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000; 
}

.MuiAppBar-root {
  background-color: #31496B !important; 
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}